import * as types from "./actionTypes";

const initialState = {
    log: null,
    n: 0,
};

export const logger = (state = initialState, action) => {

    switch (action.type) {

        case types.SET_LOGGER:
            return {
                ...state,
                log: action.payload
            };

        case types.ADD_LOGGER:
            return {
                ...state,
                log: {
                    ...state.log,
                    [state.n]: action.payload,
                },
                n: state.n + 1,
            };

        default:
            return state;
    }
};